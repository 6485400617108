import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../reactRoute/RouteConstants";
import { PROJECT_TYPE } from "../../constants/Index";

export function useNavigationManager() {

    const navigate = useNavigate();

    const fnNavigateToProjectDetails =({ type, project, userProjectRole, isInvited = false })=> {
        const projectId = project?.id;
        const invitationProjectId = project?.project_id;
        
        navigate(ROUTES.projectDetails, { state : { type, projectId : !isInvited && projectId, invitationProjectId : isInvited && invitationProjectId , userProjectRole  } });
    };

    const fnNavigateToLanguage =({ language , projectName, type, project, userRole })=> {

        const isProjectPersonal = type == PROJECT_TYPE.personal;

        if(isProjectPersonal) {
            navigate(ROUTES.language, { state: { language , projectName, type, userRole } });
        } else {
            navigate(ROUTES.language, { state: { language , projectName, type, project, userRole } });
        }
    };

    const fnNavigateToSettings =({ projectId })=> {
        navigate(ROUTES.settings, { state : { projectId } });
    };

    const fnNavigateToFile =({ file, projectName, userRole })=> {
        navigate(ROUTES.file, { state: { file, projectName, userRole } });
    };

    const fnGoBack = () => { navigate(-1); };

    return {
        fnNavigateToProjectDetails,
        fnNavigateToLanguage,
        fnNavigateToSettings,
        fnNavigateToFile,
        fnGoBack,
    }
}