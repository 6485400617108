import { Close } from '@mui/icons-material';
import { IconButton, Modal } from '@mui/material';
import './DZModal.css';
import React from 'react';

export default function DZModal({ children, open, onClose, modalStyle, closeBtn, onKeyDown =()=>{}, disabled = false }) {
  // const modalBox = { ...styles.modalBox, ...modalStyle };

  const fnKeyPress = (e, callback) => {
    if (e?.key == 'Enter') {
      if(!disabled) {
          callback();
        }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className='modalBox'  style={modalStyle} onKeyDown={(e) => fnKeyPress(e, onKeyDown)}>
        <div style={{ width: '100%', justifyContent: 'flex-end', display: 'flex', marginBottom: 5, }}>
          <IconButton onClick={onClose}  >
            <Close />
          </IconButton>
        </div>
        {children}
      </div>
    </Modal>
  );
}