export const ROUTES = {
    home: '/',
    login: '/login',
    about: '/about',
    features: '/features',
    contact: '/contact',
    signup: '/signup',
    dashboard:'/dashboard',
    confirmCode: '/confirm-code',
    invitation: '/invitation',
    forgetPassword: '/forget-password',
    language: '/language',
    file: '/file',
    allFiles: '/all-files',
    projectDetails:'/project-details',
    contributor:'/contributor',
    invitationContribute:'/invitation-contribute',
    profile: '/profile',
    settings: '/settings',
}
