import React, { useEffect, useRef, useState } from 'react'
import { InfinityLoader } from '../../components/loader/Loader';
import { Navigate, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../reactRoute/RouteConstants';
import { Element, scroller, animateScroll as scroll, scrollSpy } from 'react-scroll';
import MetaTags from '../../components/seo/MetaTags';
import Header from '../../components/header/Header';
import { META_TAGS } from '../../constants/Index';
import { useUserManager } from '../../lib/customHooks/useUserManager';

const Home = () => {
  const navigate = useNavigate();

  const { isLoggedInUser } = useUserManager();
  if(isLoggedInUser){
    return <Navigate  to={ROUTES.dashboard}/>
  };

  const description = `
  Experience seamless communication with our free translation app. 
  Break language barriers, connect globally, and translate text effortlessly. Get started now!
  `;

  const HomeSection = () => (
    <Element name="Home">
      <div className='min-h-[80vh] flex items-center gap-[24px] flex-col pt-[100px] pb-[100px] px-[64px] max-sm:px-[24px] z-[1] relative'>
        <MetaTags href={"home"} pageName={META_TAGS.home} content={description} metaTitle={"Free Unlimited Language Translation App: Break Barriers, Connect Globally!"} />

        <div className="flex items-center gap-[32px] relative">
          <div className='bg-gradient-to-b from-transparent to-[#F4F0F8] absolute inset-[-28px] z-[1]'></div>
          <img src="images/hero/file1.png" alt="" className='-rotate-[32deg] anime__left' />
          <img src="images/hero/file3.png" alt="" className='-translate-y-[36px] anime__center' />
          <img src="images/hero/file2.png" alt="" className='rotate-[32deg] anime__right' />
        </div>

        <span className='heading1 text-[var(--neutral-700)] max-w-[717px] text-center'>
          We provide <span className='text-[var(--primary-700)]'>unlimited <br /> language</span> translation & unlimited  <span className='text-[var(--primary-700)]'>projects, </span>
          for <span className='text-[var(--primary-700)]'> free & fair </span> now
        </span>

        <p className='text-[var(--neutral-700)] max-w-[1090px] text-center'>
          A cutting-edge localization platform engineered for lightning-fast performance and effortless automation.
        </p>

        <div className="flex items-center gap-[20px] max-sm:flex-col">

          <span onClick={() => navigate(ROUTES.login)} className="btn">
            Get Started
          </span>

        </div>

      </div>
    </Element>
  );

  const AboutSection = () => (
    <Element name="About">
      <div className='flex items-center gap-[15px] flex-col pb-[64px] px-[64px] max-sm:px-[24px] z-[1] relative'>
        {/* <h1 className="heading1 text-[var(--neutral-700)] max-[1100px]:text-center">
          <span className='text-[var(--primary-700)]'>About us</span>
        </h1> */}
        <p className='text-[var(--neutral-700)] max-w-[1090px] text-center'>
          Your go-to destination for seamless and comprehensive language translation services. At Byte translate, we're dedicated to breaking down language barriers and fostering global communication.      </p>
      </div>
    </Element>
  );

  const FeatureSection = () => (
    <Element name="Features">
      <div className='flex items-center justify-between px-[64px] pb-[64px] pt-[64px]  gap-[32px] max-[1100px]:flex-col max-sm:px-[24px] z-[1] relative'>

        <div className="flex flex-col gap-[24px] max-w-[694px] max-[1100px]:items-center max-sm:gap-[16px]">

          <h1 className="heading1 text-[var(--neutral-700)] max-[1100px]:text-center">
            A wide range of <span className='text-[var(--primary-700)]'>translation options</span> are <span>available</span>
          </h1>

          <p className="text-[var(--neutral-700)] max-[1100px]:text-center">
            Unlock a world of linguistic possibilities with our extensive array of translation options. Whether you're looking to bridge language barriers in business communications, understand foreign literature, or connect with global audiences, our versatile suite of translation services has you covered
          </p>

          <div className="flex items-center gap-[20px]">

            <span onClick={() => navigate(ROUTES.login)} className="btn">
              Login
            </span>

          </div>

        </div>

        <img src="images/feature1.png" alt="" className='effect__pink rounded-[26px] max-[1426px]:max-w-[600px] max-[1240px]:max-w-[500px] max-[1100px]:max-w-full' />

      </div>
    </Element>
  );

  const ContactSection = () => (
    <Element name="Contact">
      <div className='flex items-center gap-[15px] flex-col pt-[64px] pb-[64px] px-[64px] max-sm:px-[24px] z-[1] relative'>
        {/* <h1 className="heading1 text-[var(--neutral-700)] max-[1100px]:text-center">
          <span className='text-[var(--primary-700)]'>Conatct us</span>
        </h1> */}
        <p className='text-[var(--neutral-700)] max-w-[1090px] text-center'>
          Our dedicated customer support team is available around the clock to assist you with any inquiries or issues you may have. You can reach out to us via:
          <h5 className="text-[var(--neutral-500)] max-[1100px]:text-center">
            <span className='text-[var(--primary-700)]'>  Email:</span><span> info@danzeetech.com</span>
          </h5>

        </p>
      </div>
    </Element>
  );

  return (
    <div className="bg-[#F3EDF7] overflow-x-hidden min-h-screen">
      <Header />
      <HomeSection />
      <AboutSection />
      <FeatureSection />
      <ContactSection />
    </div>
  );
};

export default Home;