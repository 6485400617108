import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from './RouteConstants';
import { useUserManager } from '../lib/customHooks/useUserManager';

const ConditionalRoutes = () => {
    const { isLoggedInUser } = useUserManager();
    if(isLoggedInUser){
      return <Navigate  to={ROUTES.dashboard}/>
    }
  return <Outlet/>
}

export default ConditionalRoutes;