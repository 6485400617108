import React, { useState } from "react";
import DZInput from "../../components/shared/inputField/DZInput";
import DZBtn, { DZOldBtn } from "../../components/shared/buttons/DZBtn";
import { Config } from "../../constants/Index";
import NewPassword from "../newPassword/NewPassword";
import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
import { useVerifyEmailForForgotPasswordMutation } from "../../redux/storeApis";

const VerifyEmail = ({ email }) => {

  const { fnShowSnackBar } = useSnackBarManager();

  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [codeConfirmation, setCodeConfirmation] = useState(false);

  const [verifyEmailForForgotPassword, { isLoading }] = useVerifyEmailForForgotPasswordMutation();

  const handleInputChange = (e) => {
    setVerificationCode(e.target.value);
  };

  const fnVerifyEmail = async () => {
    if (verificationCode) {
      setLoading(true);
      try {

        const response = await verifyEmailForForgotPassword({ email, verification_code: verificationCode }).unwrap();
        if (response.success) {

          fnShowSnackBar("Email verified successfully. You can now reset your password.");
          setCodeConfirmation(true);
          setLoading(false);
        } else {
          console.log('Email not verified due to some issue')
        }
      } catch (error) {
        fnShowSnackBar("Verification failed. Please check your verification code and try again.");
        setLoading(false);
      }
    } else {
      fnShowSnackBar("Something went wrong. Please try again later.");
      setLoading(false);
    }
  };

  const handleEnterKey = (e) => {
    if (e.key == "Enter") {
      fnVerifyEmail();
    }
  };

  return (
    <>
      {codeConfirmation ? (
        <NewPassword email={email} verificationCode={verificationCode} />
      ) : (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Verify Email
            </h2>

            <p className="mt-4 text-center text-gray-600">
              A verification Code has been sent to {email}. Please check your
              email inbox
            </p>
            <div className="mt-6">
              <label
                htmlFor="verificationCode"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Verification Code
              </label>
              <div className="mt-2">
                <DZInput
                  id="verificationCode"
                  name="verificationCode"
                  type="text"
                  placeholder="Enter verification code"
                  value={verificationCode}
                  onChange={handleInputChange}
                  onKeyDown={handleEnterKey}
                />
              </div>
            </div>
            <div className="mt-6 flex justify-center">
              <DZBtn onClick={fnVerifyEmail} disabled={loading} >
                {loading ? "Loading..." : "Verify Email"}
              </DZBtn>
            </div>

          </div>
        </div>
      )}
    </>
  );
};

export default VerifyEmail;
