import { useSelector } from "react-redux";
import { useGetUserDataQuery } from "../../redux/storeApis"
import { selectedLoginUser } from "../../redux/UserReducer";
import { Config } from "../../constants/Index";
import { getLocalStorage } from '../../components/localStorage'

export function useUserManager() {

    const { data: userData, isLoading: isLoadingUser, isSuccess: isSuccessUser } = useGetUserDataQuery();

    const userDetails = userData?.data?.user;
    const userName = userDetails?.name;
    const userEmail = userDetails?.email;
    const userMobile = userDetails?.mobile;

    // const isLoggedInUser = useSelector(selectedLoginUser);
    const isLoggedInUser = getLocalStorage(Config.userToken);

    return {
        userDetails,
        userName,
        userEmail,
        userMobile,
        isLoggedInUser,

        isLoadingUser,
        isSuccessUser,

    }
}