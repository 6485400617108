import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Config } from '../constants/Index';

const TAG_TYPES = { user: 'user', projects: 'projects', translations: 'translations', user_projects: 'user_projects', invitations: 'invitations', projectFtp: 'projectFtp', fileFtp: 'fileFtp' }

export const byteTranslateApi = createApi({

    reducerPath: 'byteTranslateApi',

    baseQuery: fetchBaseQuery({
        baseUrl: Config.serverApiUrl,
        prepareHeaders: async (headers, { getState, endpoint }) => {
            const storedToken = localStorage.getItem(Config.userToken);
            if (storedToken && endpoint !== 'refresh') headers.set('Authorization', `Bearer ${storedToken}`);
            return headers;
        },
    }),

    tagTypes: [TAG_TYPES.user, TAG_TYPES.projects, TAG_TYPES.translations, TAG_TYPES.user_projects, TAG_TYPES.invitations, TAG_TYPES.projectFtp, TAG_TYPES.fileFtp],

    endpoints: (builder) => ({

        getUserData: builder.query({ query: () => "me", providesTags: () => [TAG_TYPES.user] }),
        updateUserData: builder.mutation({ query: (data) => ({ url: `userupdate`, method: "POST", body: data, }), invalidatesTags: [TAG_TYPES.user] }),
        addUser: builder.mutation({ query: (data) => ({ url: `signup`, method: "POST", body: data, }), invalidatesTags: [TAG_TYPES.user] }),
        addLoginUser: builder.mutation({ query: (data) => ({ url: `login`, method: "POST", body: data, }), invalidatesTags: [TAG_TYPES.user] }),
        getFacebookLoginRoute: builder.query({ query: () => 'login-facebook', providesTags: () => [TAG_TYPES.user] }),
        handleFacebookLogin: builder.mutation({ query: (data) => ({ url: 'handle-facebook-login', method: 'POST', body: data }), invalidatesTags: [TAG_TYPES.user] }),

        forgotPassword: builder.mutation({ query: (email) => ({ url: `forgot-password`, method: "POST", body: { email }, }) }),
        verifyEmailForForgotPassword: builder.mutation({ query: (data) => ({ url: `verify-email-forgot-password`, method: "POST", body: data, }) }),
        updatePassword: builder.mutation({ query: (data) => ({ url: `new-password`, method: "POST", body: data, }) }),

        getLanguages: builder.query({ query: () => "languages", transformResponse: (response) => response?.data }),

        getTranslationProgress: builder.query({ query: (project_id) => `translation-progress?project_id=${project_id}`, transformResponse: (response) => response?.data }),

        // getTranslationProgress: builder.mutation({
        //     query: (id) => ({
        //       url: 'translation-progress',
        //       method: 'POST',
        //       body: { project_id: id },
        //     }),
        //   }),

        getProjects: builder.query({ query: () => "projects", transformResponse: (response) => response?.data, providesTags: () => [TAG_TYPES.projects] }),
        getProjectById: builder.query({ query: (id) => `projects/${id}`, transformResponse: (response) => response?.data, providesTags: () => [TAG_TYPES.projects] }),
        addProject: builder.mutation({ query: (data) => ({ url: 'projects', method: 'POST', body: data }), invalidatesTags: [TAG_TYPES.projects] }),
        deleteProject: builder.mutation({ query: (id) => ({ url: `projects/${id}`, method: 'DELETE' }), invalidatesTags: [TAG_TYPES.projects] }),
        updateProject: builder.mutation({ query: (data) => ({ url: `projects/${data?.id}`, method: 'PUT', body: data }), invalidatesTags: [TAG_TYPES.projects] }),

        addLanguage: builder.mutation({ query: (data) => ({ url: 'project_languages', method: 'POST', body: data }), invalidatesTags: [TAG_TYPES.projects] }),
        updateLanguage: builder.mutation({ query: (data) => ({ url: `project_languages/${1}`, method: 'PUT', body: data }), invalidatesTags: [TAG_TYPES.projects] }),
        deleteLanguage: builder.mutation({ query: (id) => ({ url: `project_languages/${id}`, method: 'DELETE' }), invalidatesTags: [TAG_TYPES.projects] }),

        getKeys: builder.query({ query: (id) => `keys?project_id=${id}`, transformResponse: (response) => response?.data, providesTags: () => [TAG_TYPES.translations] }),
        addKey: builder.mutation({ query: (data) => ({ url: 'keys', method: 'POST', body: data }), invalidatesTags: [TAG_TYPES.translations] }),
        deleteKey: builder.mutation({ query: (id) => ({ url: `keys/${id}`, method: 'DELETE' }), invalidatesTags: [TAG_TYPES.translations] }),
        updateKey: builder.mutation({ query: (data) => ({ url: `keys/${data?.id}`, method: 'PUT', body: data }), invalidatesTags: [TAG_TYPES.translations] }),

        getProjectFiles: builder.query({ query: (id) => `files?project_id=${id}`, transformResponse: (response) => response?.data, providesTags: () => [TAG_TYPES.projects] }),
        addFile: builder.mutation({ query: (data) => ({ url: 'files', method: 'POST', body: data }), invalidatesTags: [TAG_TYPES.projects, TAG_TYPES.translations] }),
        deleteFile: builder.mutation({ query: (id) => ({ url: `files/${id}`, method: 'DELETE' }), invalidatesTags: [TAG_TYPES.projects, TAG_TYPES.translations] }),
        updateFile: builder.mutation({ query: (data) => ({ url: `files/${data?.id}`, method: 'PUT', body: data }), invalidatesTags: [TAG_TYPES.projects, TAG_TYPES.translations] }),

        getTranslations: builder.query({ query: (id) => `translations?project_id=${id}`, transformResponse: (response) => response?.data, providesTags: () => [TAG_TYPES.translations] }),
        addTranslation: builder.mutation({ query: (data) => ({ url: 'translations', method: 'POST', body: data }), invalidatesTags: [TAG_TYPES.translations] }),
        updateTranslation: builder.mutation({ query: (data) => ({ url: `translations/${1}`, method: 'PUT', body: data }), invalidatesTags: [TAG_TYPES.translations] }),
        uploadTranslation: builder.mutation({ query: (data) => ({ url: 'upload-translations', method: 'POST', body: data }), invalidatesTags: [TAG_TYPES.translations] }),
        downloadTranslation: builder.mutation({ query: (data) => ({ url: 'download-translations', method: 'POST', body: data }), invalidatesTags: [TAG_TYPES.translations] }),

        acceptInvitation: builder.mutation({ query: (data) => ({ url: 'accept-invitation', method: 'POST', body: data, }), invalidatesTags: [TAG_TYPES.invitations] }),
        sendInvitation: builder.mutation({ query: (data) => ({ url: 'send-invitation', method: 'POST', body: data }), invalidatesTags: [TAG_TYPES.invitations] }),

        userProjects: builder.query({ query: () => "user-projects", transformResponse: (response) => response?.data, providesTags: () => [TAG_TYPES.user_projects] }),
        getUserProject: builder.query({ query: (id) => `user-projects/${id}`, transformResponse: (response) => response?.data, providesTags: () => [TAG_TYPES.user_projects] }),
        deleteUserProject: builder.mutation({ query: (id) => ({ url: `user-projects/${id}`, method: 'DELETE' }), invalidatesTags: [TAG_TYPES.user_projects] }),

        getInvitations: builder.query({ query: (id) => `invitations?project_id=${id}`, transformResponse: (response) => response?.data, providesTags: () => [TAG_TYPES.invitations] }),
        deleteInvitation: builder.query({ query: (data) => `delete-invitations?id=${data.id}&project_id=${data.project_id}`, providesTags: () => [TAG_TYPES.invitations] }),
        getInvitationInfo: builder.query({ query: (slug) => `invitation-info?slug=${slug}`, providesTags: () => [TAG_TYPES.invitations] }),

        verifyOTP: builder.mutation({ query: (data) => ({ url: 'verify-otp', method: 'POST', body: data, }), invalidatesTags: [TAG_TYPES.user], }),

        fileDeployToServer: builder.mutation({ query: (data) => ({ url: 'deploy-to-server', method: 'POST', body: data, }), }),

        getAllUserRoles: builder.query({ query: () => ({ url: 'roles' }) }),

        addProjectFtp: builder.mutation({ query: (data) => ({ url: 'project-ftp', method: 'POST', body: data, }), invalidatesTags: [TAG_TYPES.projectFtp] }),
        updateProjectFtp: builder.mutation({ query: (data) => ({ url: `project-ftp/${data?.id}`, method: 'PUT', body: data, }), invalidatesTags: [TAG_TYPES.projectFtp] }),
        deleteProjectFtp: builder.mutation({ query: (id) => ({ url: `project-ftp/${id}`, method: 'DELETE', }), invalidatesTags: [TAG_TYPES.projectFtp] }),
        getProjectFtp: builder.query({ query: (id) => `project-ftp?id=${id}`, providesTags: () => [TAG_TYPES.projectFtp] }),

        addFileFtps: builder.mutation({ query: (data) => ({ url: 'file-ftps', method: 'POST', body: data }), invalidatesTags: [TAG_TYPES.fileFtp] }),
        updateFileFtps: builder.mutation({ query: (data) => ({ url: `file-ftps/${data?.id}`, method: 'PUT', body: data }), invalidatesTags: [TAG_TYPES.fileFtp] }),
        getFileFtps: builder.query({ query: (id) => `file-ftps/${id}`, providesTags: () => [TAG_TYPES.fileFtp] }),

        getFileFtpDetail: builder.query({ query: (data) => `get-file-ftp?language_id=${data?.language_id}&file_id=${data?.file_id}`, providesTags: () => [TAG_TYPES.fileFtp] }),

    }),

});

export const { useGetUserDataQuery, useUpdateUserDataMutation, useAddUserMutation, useAddLoginUserMutation, useGetProjectsQuery, useAddProjectMutation, useDeleteProjectMutation, useGetTranslationsQuery,
    useAddLanguageMutation, useGetProjectByIdQuery, useGetLanguagesQuery,
    useGetTranslationProgressQuery,
    useUpdateLanguageMutation, useGetProjectFilesQuery, useAddFileMutation, useDeleteFileMutation,
    useDeleteLanguageMutation, useAddTranslationMutation, useGetKeysQuery, useUpdateTranslationMutation, useAddKeyMutation, useDeleteKeyMutation, useUpdateKeyMutation,
    useUpdateFileMutation, useUploadTranslationMutation, useUpdateProjectMutation, useDownloadTranslationMutation, useAcceptInvitationMutation, useSendInvitationMutation,
    useUserProjectsQuery, useGetUserProjectQuery, useDeleteUserProjectMutation, useGetInvitationsQuery, useDeleteInvitationQuery, useGetInvitationInfoQuery, useVerifyOTPMutation, useGetFacebookLoginRouteQuery,
    useHandleFacebookLoginMutation, useFileDeployToServerMutation, useGetAllUserRolesQuery,
    useAddProjectFtpMutation, useUpdateProjectFtpMutation, useDeleteProjectFtpMutation, useGetProjectFtpQuery,
    useAddFileFtpsMutation, useUpdateFileFtpsMutation, useGetFileFtpsQuery, useGetFileFtpDetailQuery, useForgotPasswordMutation,useVerifyEmailForForgotPasswordMutation, useUpdatePasswordMutation,
} = byteTranslateApi;