import { IconButton, InputAdornment } from "@mui/material";
import React, { forwardRef } from "react";

const DZInput = forwardRef(
  (
    { id, name, type, placeholder, value, onChange, onKeyDown,disabled = false, className, style, readOnly = false, ...rest }, ref, ) => {
    return (
      <div>
        {" "}
        <input
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          style={style}
          onKeyDown={onKeyDown}
          readOnly={readOnly}
          disabled={disabled}
          className={
            className ??
            "p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          }
          ref={ref}
          {...rest}
        />
       
      </div>
    );
  }
);

export default DZInput;