import React, { useEffect, useState } from "react";
import DZInput from "../../components/shared/inputField/DZInput";
import DZBtn from "../../components/shared/buttons/DZBtn";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../reactRoute/RouteConstants";
import "./VerificationCode.css";
import { useVerifyOTPMutation } from "../../redux/storeApis";
import { useNavigationManager } from "../../lib/customHooks/useNavigationManager";

const VerificationCode = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { fnGoBack } = useNavigationManager();

  const [verifyOTP, { isLoading, isSuccess, data, error }] =
    useVerifyOTPMutation();

  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState(
    "A verification code has been sent to your email"
  );
  const [email, setEmail] = useState(location?.state?.email ?? "");

  useEffect(() => {
    if (!location?.state?.email) {
      console.log("Email not available");
      fnGoBack();
    }
  }, []);

  const handleKeyDown = (e) => {
    console.log("keydown", e.key);
    if (e.key === "Enter") {
      handleOTPVerification(e);
    }
  };

  const handleInputChange = (e) => {
    setOtp(e.target.value);
  };

  const handleOTPVerification = async (e) => {
    e.preventDefault();
    
    console.log("hello from enter");
    if (!otp || !email) {
      console.log("OTP required.");
      return;
    }

    try {
      const result = await verifyOTP({ email, otp }).unwrap();
      console.log("result", result);
    } catch (err) {
      console.error("Error verifying OTP:", err);
      setErrorMessage("Invalid OTP");
    }
  };

  useEffect(() => {
    if (isSuccess && data?.status === "success") {
      // Handle successful OTP verification
      navigate(ROUTES.login, { state: { user: data.user } }); // Pass the user data to the login page
    }
    if (error) {
      setErrorMessage("⛔ Invalid OTP");
    }
  }, [isSuccess, data, error, navigate]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="login__form">
        <div className="form__heading">Enter OTP Code</div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <div className="form__fields">
          <DZInput
            id="otp"
            name="otp"
            type="text"
            placeholder="Enter OTP code"
            value={otp}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className={"input__field"}
          />
        </div>
        <DZBtn
          btnStyle={{
            marginTop: 0,
            width: "100%",
            borderRadius: 16,
            height: 50,
            fontSize: 20,
          }}
          children={isLoading ? "Loading..." : "Verify OTP"}
          onClick={handleOTPVerification}
        />
      </div>
    </div>
  );
};

export default VerificationCode;
