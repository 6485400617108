import React, { useState } from 'react'
import { useAddFileMutation, useDeleteFileMutation, useUpdateFileMutation } from '../../redux/storeApis'
import { useLocation } from 'react-router-dom';
import { TextField } from '@mui/material';
import { AddIcon, DeleteIcon, EditIcon } from '../../icons/Icons';
import { useSnackBarManager } from '../../lib/customHooks/useSnackBarManager';
import { SNACK_BAR_MSG } from '../../constants/Index';
import DZModal from '../../components/modal/DZModal';
import DZBtn from '../../components/shared/buttons/DZBtn';
import { useGetUserRole } from '../../lib/customHooks/useGetUserRole';
import { useNavigationManager } from '../../lib/customHooks/useNavigationManager';

const AllFilesPage = ({ allFiles, projectName, userRole }) => {

    const location = useLocation();
    const { isUserOwner } = useGetUserRole(userRole);
    const { fnNavigateToFile } = useNavigationManager();

    const selectedProjectId = location?.state?.projectId;

    const [addFile, { isLoading: isLoadingAddFile }] = useAddFileMutation();
    const [deleteFile, { isLoading: isLoadingDeleteFile }] = useDeleteFileMutation();
    const [updateFile, { isLoading: isLoadingUpdateFile }] = useUpdateFileMutation();

    const { fnShowSnackBar } = useSnackBarManager();

    const [addFileModal, setAddFileModal] = useState(false);
    const [updateFileModal, setUpdateFileModal] = useState({ status: false, id: null });
    const [deleteModal, setDeleteModal] = useState({ status: false, id: null });
    const [fileName, setFileName] = useState('');

    const isExistFiles = allFiles?.length > 0;

    const fnAddFile = () => {
        if (fileName && selectedProjectId) {
            const body = { project_id: selectedProjectId, name: fileName };
            addFile(body).unwrap().then((payload) => {
                if (payload.success) {
                    setAddFileModal(false);
                    setFileName('');
                    fnShowSnackBar('File added successfully!')
                }
            }
            ).catch((error) => {
                setAddFileModal(false);
                setFileName('');
                fnShowSnackBar(SNACK_BAR_MSG.ERROR)
            });
        } else {
            fnShowSnackBar('Please add file name!')
        }
    };

    const fnDeleteFile = () => {
        deleteFile(deleteModal?.id).unwrap().then((payload) => {
            if (payload.success) {
                setDeleteModal({ id: null, status: false });
                fnShowSnackBar('File deleted successfully!')
            }
        }
        ).catch((error) => {
            setDeleteModal({ id: null, status: false });
            fnShowSnackBar(SNACK_BAR_MSG.ERROR)
        });
    };

    const fnUpdateFile = () => {
        const body = { id: updateFileModal?.id, project_id: selectedProjectId, name: fileName };
        updateFile(body).unwrap().then((payload) => {
            if (payload.success) {
                setUpdateFileModal({ status: false, id: null });
                setFileName('');
                fnShowSnackBar('File name updated successfully!')
            }
        }
        ).catch((error) => {
            setUpdateFileModal({ status: false, id: null });
            setFileName('');
            fnShowSnackBar(SNACK_BAR_MSG.ERROR)
        });

    };

    return (
        <div>

            <div style={{ marginTop: '2%', marginBottom: '1%' }} className='project__div add__bar'>

                <h1 className='project__name'>{isExistFiles ? 'All Files' : 'No Files'}</h1>

               { isUserOwner && <div onClick={() => setAddFileModal(true)} className='add__btn'>
                    <AddIcon />
                </div>}

            </div>

            {isExistFiles && allFiles?.map((file) => {
                return (
                    <div className="project__div">
                        <h3 className='project__heading' onClick={() => fnNavigateToFile({ file, projectName, userRole })} >{file?.name}</h3>
                        <div className="flex items-center gap-[24px]">

                            { isUserOwner && <div onClick={() => { setUpdateFileModal({ status: true, id: file?.id }); setFileName(file?.name) }} className="add__btn" >
                                <EditIcon />
                            </div>}

                            { isUserOwner && <div className="add__btn" onClick={() => setDeleteModal({ status: true, id: file?.id })} >
                                <DeleteIcon />
                            </div>}

                        </div>
                    </div>
                )
            })}

            <DZModal open={addFileModal} disabled={isLoadingAddFile} onKeyDown={fnAddFile} onClose={() => setAddFileModal(false)} >
                <TextField id="outlined-basic" value={fileName} onChange={(event) => setFileName(event.target.value)} label="File Name" variant="outlined" style={{ width: '100%' }} />
                <DZBtn disabled={isLoadingAddFile} onClick={() => fnAddFile()} variant="contained">
                    {isLoadingAddFile ? 'Loading...' : 'Add File'}
                </DZBtn>
            </DZModal>
            
            <DZModal open={updateFileModal.status} disabled={isLoadingUpdateFile} onKeyDown={fnUpdateFile} onClose={() => { setFileName(''); setUpdateFileModal({ ...updateFileModal, status: false }) }} >
                <TextField id="outlined-basic" value={fileName} onChange={(event) => setFileName(event.target.value)} label="File Name" variant="outlined" style={{ width: '100%' }} />
                <DZBtn disabled={isLoadingUpdateFile} onClick={() => fnUpdateFile()} variant="contained">
                    {isLoadingUpdateFile ? 'Loading...' : 'Update File'}
                </DZBtn>
            </DZModal>

            <DZModal open={deleteModal.status} disabled={isLoadingDeleteFile} onKeyDown={fnDeleteFile} onClose={() => setDeleteModal({ ...deleteModal, status: false })} >
                <h1>{'Are you sure to want to delete this file ?'}</h1>
                <DZBtn disabled={isLoadingDeleteFile} onClick={() => fnDeleteFile()} variant="contained">
                    {isLoadingDeleteFile ? 'Loading...' : 'Delete File'}
                </DZBtn>
            </DZModal>

        </div>
    )
}

const styles = {
    fileBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 10,
        padding: 10,
        backgroundColor: 'grey',
        color: 'white'
    },
    heading: { fontSize: 20, fontWeight: 'bold' }

}

export default AllFilesPage