import React from 'react'
import { AddIcon } from '../../icons/Icons'

const ViewUiBox = ({ onClickAddBtn, children, projectName, className, showHeader = true, showAddIcon = true, topSpacing = false }) => {
    return (
        <div className={` ${className} home__page`} style={{ marginTop: topSpacing && '0', }}>

            {showHeader && <div className="add__bar" style={{ justifyContent: !projectName && "flex-end" }}>

                {projectName && <h1 className="project__name">{projectName}</h1>}

                {showAddIcon && <div className="add__btn" onClick={onClickAddBtn}>
                    <AddIcon />
                </div>}

            </div>}

            <div className="projects__div" style={{ marginTop: showHeader ? '20px' : '0' }}>

                {children}

            </div>

        </div>
    )
}

export default ViewUiBox