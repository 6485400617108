import { configureStore } from '@reduxjs/toolkit'
import UserReducer from './UserReducer'
import SnackBarReducer from './SnackBarReducer'
import { setupListeners } from '@reduxjs/toolkit/query'
import { byteTranslateApi } from './storeApis'

export const store = configureStore({
    reducer: {
        UserReducer: UserReducer,
        SnackBarReducer,
        [byteTranslateApi.reducerPath]: byteTranslateApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(byteTranslateApi.middleware),

})

setupListeners(store.dispatch);