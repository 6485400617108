import React, { useState } from "react";
import DZInput from "../../components/shared/inputField/DZInput";
import { DZOldBtn } from "../../components/shared/buttons/DZBtn";
import VerifyEmail from "../verifyEmail/VerifyEmail";
import { useForgotPasswordMutation } from "../../redux/storeApis";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [resetEmailSent, setResetEmailSent] = useState(false);

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const fnSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await forgotPassword(email)?.unwrap();
      
      if (result.success) {
        setMessage("Reset email sent successfully.");
        setResetEmailSent(true);
      } else {
        console.log('Due to some error, Reset email not sent.');
      }
    } catch (error) {
      setMessage("Something went wrong. Please try again later.");
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      {resetEmailSent ? (
        <VerifyEmail email={email} />
      ) : (
        <>
          <div className="login__form">
            <div className="form__heading">
              <span>Forgot Password</span>
            </div>

            <div className="form__fields">
              <DZInput id="email" name="email" type="email" placeholder="Enter your email" value={email} className={'input__field'} onChange={handleInputChange} />
            </div>

            <DZOldBtn
              toolTipText={"Reset Password"}
              textStyle={"text-F9F7F7 cursor-pointer"}
              toolTipPlacement={"bottom"}
              onClick={fnSubmit}
              className={'form__btn'}
              children={isLoading ? "Loading..." : "Reset Password"}
              disabled={isLoading}
            />
            {message && (
              <p className="mt-4 text-center text-red-500">{message}</p>
            )}

          </div>

        </>
      )}
    </div>
  );
};

export default ForgotPassword;
