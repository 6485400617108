import React from 'react'
import { useSnackBarManager } from '../../../lib/customHooks/useSnackBarManager'

const Snackbar = () => {
    const { isShowSnackBar } = useSnackBarManager();
    return (
        <>
            {isShowSnackBar && <div className='snack__bar'>
                <span>
                    {isShowSnackBar}
                </span>
            </div>}
        </>
    )
}

export default Snackbar